import React from "react";

type Props = {
	children: React.ReactNode,
	variant?: "primary" | "secondary" | "custom",
	handleClick?: () => void,
};

function Button({ children, handleClick, variant = "primary" }: Props) {
	const style = () => {
		let str;
		if (variant === "primary") {
			str =
				"bg-golden border border-golden text-sm font-semibold w-40 py-2.5 hover:opacity-90 active:opacity-70 font-serif";
		} else if (variant === "secondary") {
			str =
				"border border-primary text-sm font-normal w-40 py-2.5 hover:opacity-90 text-golden font-serif";
		} else {
			str = "";
		}

		return str;
	};

	return (
		<button onClick={handleClick} className={style()}>
			{children}
		</button>
	);
}

export default Button;
