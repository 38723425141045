import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import ScissorsImage from "../../../assets/svgs/ScissorsImage";

export default function NavBar() {
	const [show, handleShow] = useState(false);

	const ref = useRef(null);
	const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

	const useOnClickOutside = (ref, handler) => {
		useEffect(() => {
			const listener = (event) => {
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}
				handler(event);
			};
			document.addEventListener("mousedown", listener);

			return () => {
				document.removeEventListener("mousedown", listener);
			};
		}, [ref, handler]);
	};

	useOnClickOutside(ref, () => handleShow(false));

	const linkList = [
		{ name: "Hjem", link: "#" },
		{ name: "Tjenester", link: "#work" },
		{ name: "Kontakt", link: "#kontakt", styling: "rounded" },
	];

	return (
		<nav className="bg-dark_background border-gray-200 dark:bg-gray-900 min-h-[51px] sticky top-0 z-50">
			<div
				ref={ref}
				className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-5 py-10 border-b border-b-golden"
			>
				{/* <a href="#" className="flex items-center">
					<span className=" self-center text-2xl font-semibold whitespace-nowrap text-golden">
						Afro Hair Shop
					</span>
				</a> */}
				<div className="flex flex-row items-center gap-2">
					<ScissorsImage
						height={25}
						width={25}
						className="w-10 h-10"
					/>
					<button
						onClick={() => scrollToRef(ref)}
						className="text-golden text-2xl font-thin font-serif"
					>
						Afrostyle Barbers
					</button>
				</div>
				<button
					data-collapse-toggle="navbar-default"
					type="button"
					className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
					aria-controls="navbar-default"
					aria-expanded="false"
					onClick={() => handleShow(!show)}
				>
					<span className="sr-only">Open main menu</span>
					<svg
						className="w-5 h-5"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 17 14"
					>
						<path
							stroke={"#CDCCCD"}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M1 1h15M1 7h15M1 13h15"
						/>
					</svg>
				</button>
				<div
					className="hidden w-full md:block md:w-auto"
					id="navbar-default"
				>
					<ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
						{linkList.map((link, index) => {
							return (
								<li
									key={index}
									className="rounded-2xl last:border border-golden flex items-center justify-center"
								>
									<a
										href={link.link}
										className="block py-2 pl-3 pr-4 text-gray_light font-light bg-blue-700 rounded md:bg-transparent md:text-blue-700 text-sm font-serif"
										aria-current="page"
									>
										{link.name}
									</a>
								</li>
							);
						})}
					</ul>
				</div>

				<div
					className={`block w-full md:hidden transition duration-500 ease-in-out ${
						show
							? "!opacity-100 !translate-y-full"
							: "opacity-0 -translate-y-full"
					}`}
				>
					{show && (
						<div className="bg-golden w-full absolute right-2 mt-2 rounded-b-sm">
							<ul className="font-medium flex flex-col">
								{linkList.map((link, index) => {
									return (
										<li key={index}>
											<a
												href={link.link}
												className="block py-2.5 pl-3 pr-4 text-white text-sm font-light bg-blue-700 hover:bg-primary font-serif"
												aria-current="page"
											>
												{link.name}
											</a>
										</li>
									);
								})}
							</ul>
						</div>
					)}
				</div>
			</div>
		</nav>
	);
}
