import React from "react";

export default function Services() {
	return (
		<div className="flex">
			<div className="inner-container font-serif">
				<div className="text-white font-serif my-3">
					<h2 className="text-2xl">Priser</h2>
				</div>
				<div
					className="services-container bg-golden p-2.5"
					id="services"
				>
					<ul className="">
						<li className="flex items-center align-middle gap-4">
							<p className="text-white text-lg block font-medium  text-center my-2">
								Herre
							</p>
							<div className="text-white">
								.................................................
							</div>
							<p className="text-white text-lg block font-normal  text-center my-2">
								kr 300
							</p>
						</li>
						<li className="flex items-center align-middle gap-4">
							<p className="text-white text-lg block font-medium  text-center my-2">
								Ungdom
							</p>
							<div className="text-white">
								............................................
							</div>
							<p className="text-white text-lg block font-normal  text-center my-2">
								kr 250
							</p>
						</li>
						<li className="flex items-center align-middle gap-4">
							<p className="text-white text-lg block font-medium  text-center my-2">
								Barn under 12
							</p>
							<div className="text-white">
								..................................
							</div>
							<p className="text-white text-lg block font-normal  text-center my-2">
								kr 220
							</p>
						</li>
						<li className="flex items-center align-middle gap-4">
							<p className="text-white text-lg block font-medium  text-center my-2">
								Klipp med skjegg
							</p>
							<div className="text-white">
								............................
							</div>
							<p className="text-white text-lg block font-normal  text-center my-2">
								kr 350
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
