import React from "react";

function ScissorsImage({ height, width }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 256 256"
			xmlSpace="preserve"
		>
			<defs />
			<g
				style={{
					fill: "#AD987D",
					stroke: "none",
					strokeWidth: 0,
					strokeDasharray: "none",
					strokeLinecap: "butt",
					strokeLinejoin: "miter",
					strokeMiterlimit: 10,
					fillRule: "nonzero",
					opacity: 1,
				}}
				transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
			>
				<path
					d="M 15.901 82.914 c -0.926 0 -1.858 -0.081 -2.789 -0.245 c -4.173 -0.736 -7.81 -3.053 -10.24 -6.524 c -2.43 -3.471 -3.364 -7.681 -2.628 -11.854 s 3.052 -7.809 6.524 -10.239 c 3.472 -2.431 7.682 -3.359 11.854 -2.629 c 4.173 0.736 7.81 3.053 10.24 6.524 l 0 0 c 5.017 7.166 3.27 17.077 -3.896 22.095 C 22.269 81.928 19.126 82.914 15.901 82.914 z M 15.84 54.144 c -2.622 0 -5.178 0.801 -7.371 2.336 c -2.822 1.976 -4.705 4.932 -5.304 8.325 c -0.598 3.393 0.161 6.815 2.136 9.637 c 1.977 2.822 4.933 4.705 8.326 5.304 c 3.394 0.598 6.815 -0.161 9.637 -2.136 c 5.825 -4.08 7.246 -12.137 3.167 -17.963 c -1.976 -2.822 -4.932 -4.705 -8.325 -5.303 C 17.35 54.211 16.592 54.144 15.84 54.144 z"
					style={{
						fill: "#AD987D",
						stroke: "none",
						strokeWidth: 1,
						strokeDasharray: "none",
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						strokeMiterlimit: 10,
						fillRule: "nonzero",
						opacity: 1,
					}}
					transform="matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>

				<path
					d="M 15.901 82.914 c -0.926 0 -1.858 -0.081 -2.789 -0.245 c -4.173 -0.736 -7.81 -3.053 -10.24 -6.524 c -2.43 -3.471 -3.364 -7.681 -2.628 -11.854 s 3.052 -7.809 6.524 -10.239 c 3.472 -2.431 7.682 -3.359 11.854 -2.629 c 4.173 0.736 7.81 3.053 10.24 6.524 l 0 0 c 5.017 7.166 3.27 17.077 -3.896 22.095 C 22.269 81.928 19.126 82.914 15.901 82.914 z M 15.84 54.144 c -2.622 0 -5.178 0.801 -7.371 2.336 c -2.822 1.976 -4.705 4.932 -5.304 8.325 c -0.598 3.393 0.161 6.815 2.136 9.637 c 1.977 2.822 4.933 4.705 8.326 5.304 c 3.394 0.598 6.815 -0.161 9.637 -2.136 c 5.825 -4.08 7.246 -12.137 3.167 -17.963 c -1.976 -2.822 -4.932 -4.705 -8.325 -5.303 C 17.35 54.211 16.592 54.144 15.84 54.144 z"
					style={{
						fill: "#AD987D",
						stroke: "none",
						strokeWidth: 1,
						strokeDasharray: "none",
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						strokeMiterlimit: 10,
						fillRule: "nonzero",
						opacity: 1,
					}}
					transform="matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 15.833 38.823 c -3.225 0 -6.368 -0.985 -9.065 -2.873 c -7.165 -5.018 -8.913 -14.929 -3.896 -22.095 C 7.889 6.69 17.801 4.943 24.966 9.96 c 3.471 2.43 5.788 6.067 6.524 10.24 s -0.198 8.383 -2.628 11.854 c -2.43 3.471 -6.067 5.788 -10.24 6.524 C 17.691 38.742 16.759 38.823 15.833 38.823 z M 15.885 10.062 c -4.069 0 -8.077 1.916 -10.584 5.495 C 1.223 21.383 2.644 29.441 8.469 33.52 c 2.823 1.976 6.245 2.737 9.637 2.136 c 3.393 -0.598 6.349 -2.482 8.325 -5.304 c 0 0 0 0 0 0 c 1.976 -2.822 2.735 -6.244 2.136 -9.637 c -0.598 -3.393 -2.482 -6.35 -5.304 -8.326 C 21.018 10.817 18.439 10.062 15.885 10.062 z"
					style={{
						stroke: "none",
						strokeWidth: 1,
						strokeDasharray: "none",
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						strokeMiterlimit: 10,
						fill: "#AD987D",
						fillRule: "nonzero",
						opacity: 1,
					}}
					transform="matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 30.028 66.101 c -0.194 0 -0.388 -0.038 -0.571 -0.115 c -0.469 -0.195 -0.805 -0.618 -0.891 -1.119 c -0.325 -1.905 -1.044 -3.661 -2.134 -5.219 c -0.857 -1.224 -1.932 -2.291 -3.195 -3.17 C 22.838 56.2 22.6 55.747 22.6 55.262 c 0 -0.485 0.236 -0.939 0.633 -1.217 l 49.007 -34.315 c 5.673 -3.972 13.518 -2.588 17.491 3.084 c 0.226 0.322 0.314 0.721 0.246 1.108 c -0.069 0.388 -0.288 0.732 -0.61 0.958 L 30.879 65.832 C 30.625 66.01 30.328 66.101 30.028 66.101 z M 26.559 55.337 c 0.867 0.785 1.637 1.658 2.303 2.609 c 0.91 1.3 1.61 2.711 2.09 4.214 l 55.403 -38.794 c -3.218 -3.347 -8.495 -3.95 -12.414 -1.206 L 26.559 55.337 z M 73.09 20.944 h 0.01 H 73.09 z"
					style={{
						stroke: "none",
						strokeWidth: 1,
						strokeDasharray: "none",
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						strokeMiterlimit: 10,
						fill: "#AD987D",
						fillRule: "nonzero",
						opacity: 1,
					}}
					transform="matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 23.232 35.956 l 8.994 6.298 l 2.586 -1.811 l -8.254 -5.78 c 0.867 -0.786 1.638 -1.659 2.303 -2.609 c 0.911 -1.3 1.61 -2.711 2.091 -4.213 l 10.929 7.652 l 2.586 -1.811 l -13.589 -9.515 c -0.415 -0.292 -0.953 -0.349 -1.422 -0.154 c -0.469 0.196 -0.805 0.618 -0.891 1.119 c -0.326 1.906 -1.044 3.661 -2.134 5.219 c -0.856 1.223 -1.931 2.29 -3.196 3.172 c -0.397 0.277 -0.635 0.731 -0.635 1.215 C 22.599 35.224 22.835 35.678 23.232 35.956 z"
					style={{
						stroke: "none",
						strokeWidth: 1,
						strokeDasharray: "none",
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						strokeMiterlimit: 10,
						fill: "#AD987D",
						fillRule: "nonzero",
						opacity: 1,
					}}
					transform="matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 89.366 65.122 L 64.553 47.747 l -2.586 1.811 l 24.389 17.077 c -3.219 3.347 -8.494 3.947 -12.414 1.206 L 54.898 54.507 l -2.586 1.811 l 19.927 13.953 c 2.188 1.532 4.698 2.267 7.186 2.267 c 3.962 0 7.865 -1.866 10.305 -5.351 c 0.226 -0.323 0.314 -0.72 0.246 -1.109 C 89.908 65.691 89.689 65.347 89.366 65.122 z"
					style={{
						stroke: "none",
						strokeWidth: 1,
						strokeDasharray: "none",
						strokeLinecap: "butt",
						strokeLinejoin: "miter",
						strokeMiterlimit: 10,
						fill: "#AD987D",
						fillRule: "nonzero",
						opacity: 1,
					}}
					transform="matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
			</g>
		</svg>
	);
}

export default ScissorsImage;
