import React from "react";
import clsx from "clsx";

export const NumberStyles = ({ children, position }) => {
	const positionPlacement = (position) => {
		switch (position) {
			case "left":
				return "leftLine";
			case "right":
				return "rightLine";
			case "center":
				return "centerLine";
			default:
				return "nothing";
		}
	};

	return (
		<p
			className={clsx(
				"text-golden text-4xl hidden md:block font-thin",
				positionPlacement(position)
			)}
		>
			<span class="line-center">{children}</span>
		</p>
	);
};

export const WordStyles = ({ children }) => {
	return (
		<p
			className={clsx(
				"hidden md:block text-white text-2xl font-serif topLine"
			)}
		>
			<span class="horisontal-center">{children}</span>
		</p>
	);
};
