import React from "react";
import "./styling/app.css";
import Layout from "./components/Layout/Layout";
import NavBar from "./components/Layout/Navbar/Navbar";
import Maincontent from "./components/Layout/Maincontent/Maincontent";
import Button from "./components/Buttons/Button";
import Hero1 from "./assets/Hero1.jpg";
import Hero2 from "./assets/Hero2.jpg";
import Filly03 from "./assets/Filly03.jpg";
import Cut1 from "./assets/Cut1.jpg";
import Anwar from "./assets/Anwar.jpg";
import Fily from "./assets/Fily_pic.jpg";
import Dejen from "./assets/Dejen.jpg";
import { useTranslation } from "react-i18next";
import { PopupModal } from "react-calendly";
import Services from "./components/Services";
import ScissorsImage from "./assets/svgs/ScissorsImage";
import SubHeader from "./components/Headers/SubHeader";
import { NumberStyles, WordStyles } from "./components/Headers/NumberStyles";
import { motion } from "framer-motion";

export default function App() {
	const [isOpen, setIsOpen] = React.useState(false);

	const { t } = useTranslation();
	const url = "https://calendly.com/afro-shop";
	return (
		<Layout>
			<NavBar />
			<Maincontent>
				<div className="my-6 flex gap-5 flex-col md:flex-row items-center">
					<div className="order-0 md:order-1 md:w-1/2">
						<motion.img
							className="img max-h-96 w-full object-cover"
							src={Hero1}
							alt="hero 1"
							initial={{ y: -10, opacity: 0 }}
							animate={{ y: 10, opacity: 1 }}
							transition={{
								type: "smooth",
								repeatType: "mirror",
								duration: 2,
							}}
						/>
					</div>
					<div className="md:w-1/2 md:mx-5 mx-0 pb-10">
						<motion.h1
							initial={{ opacity: 0, y: 0 }}
							animate={{ opacity: 1, y: -10 }}
							transition={{ duration: 0.5 }}
							className="text-header text-5xl my-10 font-medium capitalize font-serif"
						>
							{t("shared.The Best Mens Hair Salon in Hamar")}
						</motion.h1>
						<motion.h3
							initial={{ opacity: 0, y: 0 }}
							animate={{ opacity: 1, y: -10 }}
							transition={{ duration: 2 }}
							className="text-offwhite text-sm font-light my-10 font-serif"
						>
							{t(
								"shared.Exeperice the best new hairstyle in our new hair salon. Just Book your desired day from now on and easily style your hair"
							)}
						</motion.h3>

						<div className="flex gap-4 my-4">
							<Button handleClick={() => setIsOpen(true)}>
								{t("shared.Booking")}
							</Button>
							<PopupModal
								url={url}
								onModalClose={() => setIsOpen(false)}
								open={isOpen}
								/*
								 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
								 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
								 */
								rootElement={document.getElementById("root")}
							/>
						</div>
					</div>
				</div>
				<div className="my-3">
					<ScissorsImage width={20} height={20} />
					<div className="dottedLine"></div>
				</div>

				<div className="text-center bg-dark_background" id="work">
					<div className="mt-12">
						<div className="text-center w-full flex flex-col  items-center">
							<div className="w-1/2">
								<SubHeader>
									{t("shared.Our services")}
								</SubHeader>

								<h3 className="text-offwhite text-sm font-light my-10 font-serif">
									{t(
										"shared.The number of services for now is 3 with the best quality for you"
									)}
								</h3>
							</div>
						</div>
					</div>

					<div className="flex gap-5 my-10 md:flex-row flex-col items-center">
						<div className="order-0 md:order-1 md:w-1/2">
							<div className="mb-2 w-1/2">
								<NumberStyles position={"left"}>
									01
								</NumberStyles>
							</div>
							<motion.img
								initial={{ y: 10, opacity: 0 }}
								animate={{ y: -10, opacity: 1 }}
								transition={{
									type: "smooth",
									repeatType: "mirror",
									duration: 2,
								}}
								className="img max-h-96 w-full object-cover"
								src={Hero2}
								alt="hero 1"
							/>
							<div className="mt-5">
								<WordStyles>Barbering av skjegg</WordStyles>
							</div>
						</div>
						<div className="order-0 md:order-1 md:w-1/2">
							<div className="mb-5">
								<WordStyles>Hårklipp</WordStyles>
							</div>
							<motion.img
								initial={{ y: -10, opacity: 0 }}
								animate={{ y: 10, opacity: 1 }}
								transition={{
									type: "smooth",
									repeatType: "mirror",
									duration: 2,
								}}
								className="img max-h-96 w-full object-cover"
								src={Cut1}
								alt="hero 1"
							/>
							<div className="mt-5 w-1/2">
								<NumberStyles position={"left"}>
									02
								</NumberStyles>
							</div>
						</div>
						<div className="order-0 md:order-1 md:w-1/2">
							<div className="mb-2 w-1/2">
								<NumberStyles position={"left"}>
									03
								</NumberStyles>
							</div>
							<motion.img
								initial={{ y: 10, opacity: 0 }}
								animate={{ y: -10, opacity: 1 }}
								transition={{
									type: "smooth",
									repeatType: "mirror",
									duration: 2,
								}}
								className="img max-h-96 w-full object-cover"
								src={Filly03}
								alt="hero 1"
							/>
							<div className="mt-5">
								<WordStyles>Buzzcut</WordStyles>
							</div>
						</div>
					</div>
				</div>
				<div className="flex md:justify-start justify-center">
					<Services />
				</div>

				<div id="kontakt">
					<div className="mt-12">
						<div className="text-center w-full flex flex-col  items-center">
							<div className="w-1/2">
								<SubHeader>Møt vårt team</SubHeader>
								<h3 className="mt-5  max-w-xl text-offwhite text-sm font-light font-serif ">
									{t(
										"shared.InOurSalonYouWillGetPeopleWhoArePassionateAboutTheirWork"
									)}
								</h3>
							</div>
						</div>

						<div className="flex mt-20 gap-10 items-center justify-center flex-wrap relative">
							<div className="background_block" />
							<MembersList />
						</div>
					</div>

					<div className="my-24"> </div>
				</div>
			</Maincontent>
		</Layout>
	);
}

const MembersList = () => {
	const members = [
		{
			id: 1,
			name: "Fili",
			phoneNumber: "+4797371984",
			imgSrc: Fily,
			order: true,
		},
		{
			id: 2,
			name: "Anwar",
			phoneNumber: "+4740391972",
			imgSrc: Anwar,
			order: false,
		},
		{
			id: 3,
			name: "Dejen",
			phoneNumber: "+4746725938",
			imgSrc: Dejen,
			order: false,
		},
	];
	return (
		<>
			{members.map((member, index) => {
				return <Member key={member.id} member={member} />;
			})}
		</>
	);
};

const Member = ({ member }: { member: any }) => {
	return (
		<div className="order-0 md:order-1">
			<img
				className="max-h-56 w-56 object-scale-down rounded-full bg-golden scale-100"
				src={member.imgSrc}
				alt={member.name}
			/>
			<p className="text-white text-xl block font-medium  text-center my-2">
				{member.name}
			</p>
			<p className="text-white text-md block font-thin  text-center my-2">
				Mobil nr: {member.phoneNumber}
			</p>
			<a className="text-white text-md block font-thin  text-center my-2 underline cursor-pointer">
				{!member.order ? "Ring og bestill" : "Bestill her"}
			</a>
		</div>
	);
};
