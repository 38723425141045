import React from "react";

function SubHeader({ children, withCenterLine }) {
	return (
		<h2 className="text-header text-5xl font-semibold relative centerLine font-serif">
			<span class="line-center">{children}</span>
		</h2>
	);
}

export default SubHeader;
