import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import no from "./resources/i18n/no.json";
const resources = {
	no,
};

console.log(no);

i18n.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		fallbackLng: "no",
		lng: "no",
	});
export default i18n;
